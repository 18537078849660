// for "Read More" situations based on a max-height
const isHeightOverflowed = ({  scrollHeight, clientHeight }) => scrollHeight > clientHeight;

const handleReadMore = e => {
	const container = e.target.closest('.js-read-more-overflow');
	container.classList.add("read-more-overflow--expanded");
	setOverflowClass(container);
}

const handleReadLess = e => {
	const container = e.target.closest('.js-read-more-overflow')
	container.classList.remove("read-more-overflow--expanded");
	setOverflowClass(container);
}

const setOverflowClass = overflowableEl => {
	if (isHeightOverflowed(overflowableEl)) {
		overflowableEl.classList.add("height-is-overflowed");
	} else {
		overflowableEl.classList.remove("height-is-overflowed");
	}
}

const setAllOverflowClasses = () => {
	document.querySelectorAll('.js-read-more-overflow:not(.read-more-overflow--expanded)').forEach( overflowableEl => {
		setOverflowClass(overflowableEl);
	})
}

const handleResize = () => {
	window.requestAnimationFrame(setAllOverflowClasses)
}

const initializeReadMoreOverflow = () => {
	document.querySelectorAll('.js-read-more-overflow:not(.read-more-overflow--expanded)').forEach( overflowableEl => {
		setOverflowClass(overflowableEl);
		overflowableEl.querySelector(".js-read-more-overflow__button--more").addEventListener("click", handleReadMore);
		overflowableEl.querySelector(".js-read-more-overflow__button--less").addEventListener("click", handleReadLess);
	});
	window.addEventListener("resize", handleResize)
}

export default initializeReadMoreOverflow;
