import AudioGuideDataLayerPusher from "./data-layer-pusher.js";

function audioGuide() {
	document.querySelectorAll(".js-audio-guide__input-wrapper").forEach(audioGuideInputWrapper => {
		const helperAlert = audioGuideInputWrapper.querySelector(".js-audio-guide__helper");
		const defaultHelperText = helperAlert.innerHTML;
		const noNumberDetectedText = "Please enter an audio stop number.";
		const audioGuideDataLayerPusher = new AudioGuideDataLayerPusher();

		const handleAudioStopSearch = async e => {
			e.preventDefault();
			const form = e.target;
			const stopNumber = form.querySelector("input.js-audio-guide-search-stop-input").value.replace(/\D/g, '');
			const submitButton = form.querySelector(".js-audio-guide-button");

			if (stopNumber !== "") {
				submitButton.disabled = true;
				let result = await fetchResultForStop(stopNumber);
				submitButton.disabled = false;
				if (result == 404) {
					handle404Response(stopNumber);
				} else {
					window.location.href = `/audio-guide/${stopNumber}`
				}
			} else {
				audioGuideInputWrapper.classList.add("show-alert");
				helperAlert.innerHTML = noNumberDetectedText;
			}
		}

		const handle404Response = stopNumber => {
			helperAlert.innerHTML = defaultHelperText;
			audioGuideInputWrapper.classList.add("show-alert");
			try {
				audioGuideDataLayerPusher.sendCustomEvent({
					event: "404",
					notFoundStopNumber: stopNumber,
				})
			} catch (err) {
				console.err(err);
			}
		}

		const resetForm = () => {
			helperAlert.innerHTML = defaultHelperText;
			audioGuideInputWrapper.classList.remove("show-alert");
		}

		const fetchResultForStop = stopNumber => {
			const url = `/audio-guide/${stopNumber}`

			return fetch(url, { headers: { "X-Requested-With": "XMLHttpRequest" } })
				.then(response => {
					return response.status
				})
				.catch(
					e => {
						console.error(e)
						// todo: send to analytics
					}
				);
		};

		const initializeAudioStopSearch = () => {
			const audioGuideSearchForms = document.querySelectorAll(".js-audio-guide-stop-search-form");
			audioGuideSearchForms.forEach(searchForm => {

				searchForm.addEventListener("submit", handleAudioStopSearch);

				const audioGuideInput = searchForm.querySelector(".js-audio-guide-search-stop-input");
				audioGuideInput.addEventListener("keydown", resetForm);
			});
		}

		initializeAudioStopSearch();
	});
}

export default audioGuide;
