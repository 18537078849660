import "./application.scss";
import marble from "@metmuseum/marble";

import initializeExhibitionTabs from "./components/exhibition_tabs";
import initializeExhibitionMap from "./components/exhibition-map";
import initializeAudioGuidePlayers from "./components/audio-guide";
import initializeAudioPlayers from "./components/audio-player";
import audioGuideSearch from "./components/audio-guide/search.js";
import navigationBare from "@metmuseum/marble/src/components/navigation/navigation-bare/navigation-bare.js";
import verticalListFlickity from "./components/two-verticals/two-verticals.js";
import initializeCardContainerCarousel from "./components/featured-content/featured-content--carousel";

//TODO clean these up
import initializeGalleryslider from "./components/gallery-slider/gallery-slider.js";
import initializeEmailSignUp from "./components/homepage-email/homepage-email.js";
import homepageHero from "./components/homepage-hero/homepage-hero";
import initializeVisitComponent from "./components/visit-section/visit-section";
import initializeMembershipSlider from "./components/membership-cards/membership-cards";
import imageViewerInitializer from "./components/image-viewer/image-viewer.js";
import initializeReadMoreOverflow from "./components/read-more-overflow/readMoreOverflow.js";
import hotSpotViewerComponent from "./primers/components/hotspotviewer/hotspotviewer.js";
import GrabNav from "./components/grab-nav/grab-nav.js";

const initialize = () => {
	marble.global();
	marble.carousel();
	initializeExhibitionTabs();
	initializeExhibitionMap();
	audioGuideSearch();
	verticalListFlickity()
	initializeVisitComponent();
	homepageHero.initialize();
	initializeGalleryslider();
	initializeEmailSignUp();
	initializeMembershipSlider();
	initializeAudioGuidePlayers(); // audio stops
	initializeAudioPlayers(); // generic player
	imageViewerInitializer();
	initializeReadMoreOverflow();
	(document.querySelectorAll(".js-navigation-bare").length > 0) && navigationBare();
	initializeCardContainerCarousel();
	window.marbleLazyLoadObj.loadAll();
	marble.jumpToBanner();
	document.querySelector(".js-image-viewer") && hotSpotViewerComponent();
	marble.browseBy();
	marble.viewMore();
	const GrabNavs = document.querySelectorAll('.js-grab-nav');
	GrabNavs.forEach(nav => new GrabNav(nav));
};

document.addEventListener("newContentLoaded", initialize, { bubbles: true });
initialize();
