export default class AuideGuideDataLayerPusher {
	constructor() {
		if (!window.dataLayer) {
			console.error("No dataLayer was found, not doing anything");
		}
	}

	sendCustomEvent(args) {
		try {
			window.dataLayer.push(args);
		} catch (err) {
			// TODO: send to a service like airbrake, for actual visibility
			console.error(err);
		}
	}
}
