import marble from "@metmuseum/marble";
import AudioGuideDataLayerPusher from "./data-layer-pusher.js";
import { escape } from "underscore";


export default () => {
	document.querySelectorAll(".js-audio-guide__main-wrapper").forEach(audioStopEl => {
		const analyticsSender = new AudioGuideDataLayerPusher();
		const audioStopArtWorkContainerEl = audioStopEl.querySelector(".js-audio-guide__artwork");
		const audioStopSearchInputEl = audioStopEl.querySelector(".js-audio-guide-search-stop-input");
		const audioPlayerTranscript = audioStopEl.querySelector(".audio-player__transcript-section");

		const audioStopCoverImageTemplate = image => `
			<img class="audio-guide__artwork-image" alt="${escape(image.alt)}" width="${image.width}"
						height="${image.height}" src="${image.w560}" srcset="
							${image.w2400} 2400w,
							${image.w1600} 1600w,
							${image.w1200} 1200w,
							${image.w840}  840w,
							${image.w560}  560w,
							${image.w280}  280w" sizes="(orientation: landscape) 43vw, 85vw" />`;

		const checkTranscript = () => {
			if (audioPlayerTranscript.classList.contains("audio-player__transcript-section--transcript-true")) {
				audioStopEl.classList.add("has-transcript");
			} else {
				audioStopEl.classList.remove("has-transcript")
			}
		}
		const marblePlayer = new marble.AudioPlayer({ wrapperEl: audioStopEl, options: { analyticsSender } });
		audioStopEl.addEventListener("afterTrackChange", () => {
			if (audioStopArtWorkContainerEl) {
				audioStopArtWorkContainerEl.innerHTML = marblePlayer.currentTrack.image ? audioStopCoverImageTemplate(marblePlayer.currentTrack.image) : "";
			}
			if (audioStopSearchInputEl) {
				audioStopSearchInputEl.value = marblePlayer.currentTrack.stopNumber ? marblePlayer.currentTrack.stopNumber : "";
			}
			checkTranscript();
		});
		checkTranscript();
	});
};
