import marble from "@metmuseum/marble";

const initializeCardContainerCarousel = () => {
	if (document.querySelector(".js-featured-content--carousel")) {
		marble.carousel({
			cellAlign: "left",
			selectorString: ".js-featured-content--carousel",
			watchCSS: true,
			wrapAround: false
		});
	}
};

export default initializeCardContainerCarousel;
