const html = String.raw

const restrictedImageTemplate = html`<p class="artwork__rights-notice">
	<svg class="icon artwork__rights-notice--icon" viewBox="0 0 12 12" fill="#4a4a4a" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6c0 3.309 2.691 6 6 6 3.308 0 6-2.691 6-6S9.308 0 6 0C2.69 0 0 2.691 0 6zm1.016 0A4.99 4.99 0 016 1.016 4.99 4.99 0 0110.984 6 4.989 4.989 0 016 10.984 4.989 4.989 0 011.016 6z"></path><path d="M5.354 4.869h1.292v4.524H5.354zM6 4.247a.82.82 0 100-1.64.82.82 0 000 1.64z"></path></svg> Due to rights restrictions, this image cannot be enlarged, viewed at full screen, or downloaded.
</p>`

const publicCaptionTemplate = publicCaption => html`<p class="artwork__caption">${publicCaption}</p>`

const caretIcon = "<svg xmlns='http://www.w3.org/2000/svg' width='11' height='18' viewBox='0 0 11 18' fill='none'><path fill='currentColor' d='M10.5 1.11153L9.46155 0L0 9.00561L9.4605 18L10.5 16.8885L2.0559 8.98204L10.5 1.11153Z'/></svg>";
const xIcon = "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'><path fill='currentColor' d='M18 1.125L16.875 0L9 7.875L1.125 0L0 1.125L7.875 9L0 16.875L1.125 18L9 10.125L16.875 18L18 16.875L10.125 9L18 1.125Z'/></svg>";

function imageViewer(props) {
	let modalEl;
	let fsContentEl;
	let fsCloseButton;
	let fsBackButton;
	let fsForwardButton;
	let fsContentImageEl;
	let fsContentTextEl;

	let listOfIterableElements;
	let currentIndex;

	const closeModal = () => {
		document.removeEventListener("keydown", handleKeyDown, true);
		fsContentEl.scrollTop = 0;
		modalEl.classList.remove("is-fullscreen");

		//Prevent body from scrolling while modal is up
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	};

	const createModal = () => {
		//Modal Wrapper
		modalEl = document.createElement("div");
		modalEl.classList.add("js-fs-container");

		//Close Button
		fsCloseButton = document.createElement("button");
		fsCloseButton.classList.add("js-fs-close");
		fsCloseButton.innerHTML = xIcon;
		fsCloseButton.addEventListener("click", e => {
			e.preventDefault();
			closeModal();
		});
		modalEl.append(fsCloseButton);

		//Content Container
		fsContentEl = document.createElement("div");
		fsContentEl.classList.add("js-fs-content");
		modalEl.append(fsContentEl);

		fsContentImageEl = document.createElement("div");
		fsContentImageEl.classList.add("js-fs-image");
		fsContentEl.append(fsContentImageEl);

		fsContentTextEl = document.createElement("div");
		fsContentTextEl.classList.add("js-fs-text");
		fsContentEl.append(fsContentTextEl);

		//Back Button
		fsBackButton = document.createElement("button");
		fsBackButton.classList.add("js-fs-back", "js-fs-arrow");
		fsBackButton.innerHTML = caretIcon;
		fsBackButton.addEventListener("click", e => {
			e.preventDefault();
			iterate(false);
		});
		modalEl.append(fsBackButton);

		//Forward Button
		fsForwardButton = document.createElement("button");
		fsForwardButton.classList.add("js-fs-forward", "js-fs-arrow");
		fsForwardButton.innerHTML = caretIcon;
		fsForwardButton.addEventListener("click", e => {
			e.preventDefault();
			iterate(true);
		});
		modalEl.append(fsForwardButton);

		document.body.append(modalEl);
	};

	const toggleArrowVisibility = () => {
		const disabledClass = "is-disabled";
		if (currentIndex === listOfIterableElements.length - 1) {
			fsForwardButton.classList.add(disabledClass);
			fsBackButton.classList.remove(disabledClass);
		} else if (currentIndex === 0) {
			fsBackButton.classList.add(disabledClass);
			fsForwardButton.classList.remove(disabledClass);
		} else {
			fsForwardButton.classList.remove(disabledClass);
			fsBackButton.classList.remove(disabledClass);
		}
	}

	const populateModal = el => {
		const caption = el.querySelector(props.captionSelector)?.cloneNode(true);
		const img = el.querySelector(props.imageSelector)?.cloneNode(true);
		const imageIsRestricted = JSON.parse(el.dataset.restrictedImage)
		const publicCaption = el.dataset.publicCaption ? JSON.parse(el.dataset.publicCaption) : "";

		//Clear previous content
		fsContentTextEl.innerHTML = "";
		fsContentImageEl.innerHTML = "";

		//Populate new content
		caption && fsContentTextEl.append(caption);
		img && fsContentImageEl.append(img);
		imageIsRestricted && fsContentImageEl.insertAdjacentHTML("beforeend", restrictedImageTemplate);
		publicCaption && fsContentImageEl.insertAdjacentHTML("beforeend", publicCaptionTemplate(publicCaption));

		toggleArrowVisibility();

		el.dispatchEvent(new CustomEvent("newContentLoaded", { bubbles: true }));
	};

	const iterate = forward => {
		if (forward) {
			if (currentIndex < listOfIterableElements.length - 1) {
				currentIndex++;
				populateModal(listOfIterableElements[currentIndex]);
			}
		} else if (currentIndex > 0) {
			currentIndex--;
			populateModal(listOfIterableElements[currentIndex]);
		}
	};

	const handleKeyDown = e => {
		if (e.keyCode == 37) {
			if (currentIndex > 0) {
				iterate(false);
			}
		} else if (e.keyCode == 39) {
			if (currentIndex < listOfIterableElements.length - 1) {
				iterate(true);
			}
		} else if (e.keyCode == 27) {
			closeModal();
		}
	}

	const bindArrowKeys = () => {
		document.addEventListener("keydown", handleKeyDown, true);
	};

	const openModal = (el, index, listOfElements) => {
		listOfIterableElements = listOfElements;
		currentIndex = index;
		populateModal(el);
		bindArrowKeys();
		modalEl.classList.add("is-fullscreen");

		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = 'fixed';

		el.dispatchEvent(new CustomEvent("newContentLoaded", { bubbles: true }));
	};

	const initializeIVels = ivContainer => {
		const listOfElements = ivContainer.querySelectorAll(props.elSelector);
		listOfElements
			.forEach((ivEl, index) => {
				ivEl.querySelectorAll(props.triggerSelector)
					.forEach(trigger => {
						trigger.addEventListener("click", e => {
							e.preventDefault();
							openModal(ivEl, index, listOfElements);
						});
					});
			});
	};

	document.querySelectorAll(`${props.containerSelector}:not(.${"pre-init"})`)
		.forEach(ivContainer => {
			ivContainer.classList.add("pre-init");
			initializeIVels(ivContainer);
		});

	createModal();
}

function imageViewerInitializer(propsObj) {

	const imageViewerDefaults = {
		elSelector: ".js-iv",
		triggerSelector: ".js-iv__trigger",
		containerSelector: ".js-iv__container",
		captionSelector: ".js-iv__caption",
		imageSelector: ".js-iv__image"
	};

	const props = {
		...imageViewerDefaults,
		...propsObj
	};

	document.querySelector(props.elSelector) ?
		imageViewer(props) :
		null;
}

export default imageViewerInitializer;
