function initialize(OpenSeadragon) {
	document.querySelectorAll(".js-image-viewer").forEach(imageViewer => {
		const containerElement = imageViewer.querySelector(".js-image-viewer__container");
		const tagToggle = imageViewer.querySelector(`.js-image-viewer__tags`);
		const hotSpotsContainer = imageViewer.querySelector(".js-image-viewer__hotspots");
		const hotSpots = imageViewer.querySelectorAll(".js-viewer__hotspot");
		const overlayArray = JSON.parse(hotSpotsContainer.getAttribute('data-hotspots'));
		const captionElement = containerElement.querySelector(`.js-image-viewer__caption`);
		const captionText = captionElement.querySelector(".js-image-viewer__caption-text");
		const viewerID = containerElement.id;
		const imageURL = containerElement.getAttribute(`data-image`);
		let showAll = true;

		const viewer = OpenSeadragon({
			panHorizontal: false,
			panVertical: false,
			maxZoomLevel: 1,
			minZoomLeveL: 0.4,
			constrainDuringPan: true,
			showNavigationControl: false,
			visibilityRatio: 1.0,
			id: viewerID,
			prefixUrl: "openseadragon/images/",
			zoomPerScroll: 1,
			zoomPerClick: 1,
			tileSources: {
				type: 'image',
				url: imageURL,
				overlays: overlayArray,
				useCanvas: false
			}
		});

		viewer.addHandler('full-screen', () => {
			viewer.viewport.fitVertically();
		});

		viewer.addHandler('viewport-change', () => {
			if (viewer.viewport.getZoom() > 2) {
				containerElement.classList.add("is-zoomed");
			} else {
				goHome();
			}
		});

		hotSpots.forEach(hotspot => {
			viewer.addOnceHandler('open', function() {
				new OpenSeadragon.MouseTracker({
					element: hotspot.id,
					clickHandler: () => {
						focusOnHotspot(hotspot);
					},
					keyUpHandler: e => {
						const code = (e.keyCode ? e.keyCode : e.which);
						if (code === 13) {
							focusOnHotspot(hotspot);
						} else if (code === 9 && viewer.viewport.getZoom() > 2) {
							goHome();
						} else if (code === 27) {
							goHome();
						}
					}
				});
			});
		});

		const tagFilters = tagToggle ? tagToggle.querySelectorAll(`.js-image-viewer__tag`) : [];
		tagFilters.forEach(tagFilter => {
			tagFilter.addEventListener(`click`, e => {
				e.preventDefault();
				const filterBy = tagFilter.getAttribute('data-tag');
				tagFilters.forEach(tagFilter => tagFilter.classList.remove("is-active"));
				if (filterBy === "all") {
					toggleAll(tagFilter);
				} else {
					filterTags(filterBy, tagFilter);
				}
			});
		});

		const goHome = () => {
			containerElement.classList.remove("is-zoomed");
			viewer.viewport.goHome();
		};

		const updateCaption = hotspot => {
			const caption = hotspot.querySelector(`.js-highlight__caption`).innerHTML;
			captionText.innerHTML = caption;
		};

		const focusOnHotspot = hotspot => {
			let currentOverlay = viewer.getOverlayById(hotspot.id);
			let newCenter = currentOverlay.location;
			viewer.viewport.panTo(newCenter);
			viewer.viewport.zoomTo(2.5);
			updateCaption(hotspot);
		};

		const toggleAll = tagElement => {
			showAll = !showAll;
			hotSpots.forEach(hotSpot => {
				if (showAll) {
					tagElement.classList.add("is-active");
					hotSpot.classList.remove(`is-hidden`);
				} else {
					tagElement.classList.remove("is-active");
					hotSpot.classList.add(`is-hidden`);
				}
			});
		};

		const filterTags = (filter, tagElement) => {
			showAll = false;
			hotSpots.forEach(hotSpot => {
				if (hotSpot.getAttribute('data-tag') === filter) {
					tagElement.classList.add("is-active");
					hotSpot.classList.remove(`is-hidden`);
				} else {
					hotSpot.classList.add(`is-hidden`);
				}
			});
		};

		imageViewer.querySelectorAll(".js-full-screen").forEach(button => {
			button.addEventListener("click", e => {
				e.preventDefault();
				viewer.setFullPage(!viewer.isFullPage());
				bindEscKey(viewer.isFullPage());
			});
		});

		const handleEscKey = e => {
			if (e.code === "Escape") {
				viewer.setFullPage(false);
				bindEscKey(false);
			}
		};

		const bindEscKey = shouldBind => {
			if (shouldBind) {
				document.addEventListener("keyup", handleEscKey, true);
			} else {
				document.removeEventListener("keyup", handleEscKey, true);
			}
		};

		containerElement.querySelectorAll(".js-home").forEach(button => {
			button.addEventListener("click", e => {
				e.preventDefault();
				viewer.viewport.goHome();
			});
		});

	});
}

const hotSpotViewerComponent = async () => {
	const { default: OpenSeadragon } = await import("OpenSeadragon");
	initialize(OpenSeadragon);
};

export default hotSpotViewerComponent;