function exhibitionMap(selector) {

	//Add a class to the associated galleries so they can be styled in CSS.
	document.querySelectorAll(".js-edp-exhibition-theme").forEach(theme => {
		if (theme.dataset.galleryNumber){
			const associatedGallery = theme.querySelector(`[data-gallery-number="${theme.dataset.galleryNumber}"]`);
			associatedGallery?.classList.add("is-associated")
		}
	});

	const scrollToGallerySection = e => {
		const gallery = e.target.closest(`.edp-map__gallery`);

		const galleryID =
			gallery ?
				gallery.dataset.galleryNumber :
				null;

		const sectionToScrollTo =
			galleryID ?
				document.querySelector(`.edp-exhibition-theme[data-gallery-number="${galleryID}"]`) :
				null;

		sectionToScrollTo && sectionToScrollTo.querySelector(".content-split__main").scrollIntoView({behavior: "smooth"});
	};

	document.querySelectorAll(selector).forEach(map => {
		map.addEventListener("click", scrollToGallerySection)
	});
}

function exhibitionMapInitializer() {
	const edpMapSelector = `.js-edp__map`
	document.querySelectorAll(edpMapSelector) ?
		exhibitionMap(edpMapSelector) :
		null;
}

export default exhibitionMapInitializer;
