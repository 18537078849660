import Player from "@vimeo/player";

const HomepageHero = (function () {

	function _shouldLoadVideo() {
		return window.matchMedia("(min-width: 900px)").matches;
	}

	function _applyEventListeners() {
		document.querySelectorAll(".js-homepage-hero__vimeo-container")
			.forEach(el => { _attachVimeoPlayer(el);});
	}

	function _attachVimeoPlayer(el) {
		new Player(el).on("loaded", () => {
			el.classList.add("is-visible");
		});
	}

	return {
		initialize: () => { _shouldLoadVideo() ? _applyEventListeners() : false; }
	};
});

export default new HomepageHero();
