const emailSignUp = () => {
	let pageId = null;
	let endpoint;
	const pageInfo = "web_home";

	if (window.searchConfigs && window.searchConfigs["newsletter"]) {
		endpoint = window.searchConfigs.newsletter.url;
	}

	if (window.newsletterConfig && window.newsletterConfig.searchParams["currentPageId"]) {
		pageId = window.newsletterConfig.searchParams.currentPageId;
	}

	const submit = (email, signUpModule) => {
		//Hide Previous Error State
		showErrorBanner(signUpModule, false, "");

		//Build form datat object to post to server
		const dataObj = JSON.stringify({
			emailAddress: email,
			pageInfo: pageInfo,
			currentPageId: pageId
		});

		//Post request
		fetch(endpoint, {
			method: "POST",
			headers: {
				"Accept": "application/json, text/javascript, */*; q=0.01",
				"Content-Type": "application/json"
			},
			body: dataObj
		}).then(response => response.json()
			//Handle Response
		).then(res => {
			const success = res.success;
			const message = res.message;
			updatePageAfterSubmit(res);
			success ? setSuccessState(signUpModule, message) : showErrorBanner(signUpModule, true, message);
		});
		//TODO: handle error here?
		// .catch(error => {
		// 	console.log(error);
		// });
	};

	//DataLayer Updates for GTM?
	const updatePageAfterSubmit = () => {
		window.dataLayer = (window.dataLayer instanceof Array) ? window.dataLayer : [];
		window.dataLayer.push({
			"Category": "Email",
			"Action": "Sign up",
			"event": "HomeNewsletterFormSubmitted"
		});
	};

	const bindEvents = signUpModule => {
		const formInput = signUpModule.querySelector(".js-homepage-email__input");
		const form = signUpModule.querySelector(".js-homepage-email__form");

		//Check validation on form submit.
		form.addEventListener("submit", e => {
			e.preventDefault();
			const isValid = formInput.checkValidity();

			if (isValid) {
				formInput.classList.remove("is-invalid");
				//Pass Email value to submit function
				submit(formInput.value, signUpModule);
			} else {
				formInput.classList.add("is-invalid");
			}

			showInvalidBanner(signUpModule, !isValid);
		});

		//Form Validation on Blur.
		formInput.addEventListener("blur", () => {
			const isValid = formInput.checkValidity();

			if (isValid) {
				formInput.classList.remove("is-invalid");
			} else {
				formInput.classList.add("is-invalid");
			}

			showInvalidBanner(signUpModule, !isValid);
		});

	};

	//Loop through each instance of this component on a page.
	const signUpModules = document.querySelectorAll(".js-homepage-email");

	//Bind events to each instance of the component.
	signUpModules.forEach(signUpModule => {
		bindEvents(signUpModule);
	});

	//Show success state and hide invalid state.
	const setSuccessState = (signUpModule, message) => {
		const successNode = signUpModule.querySelector(".js-homepage-email__confirm");
		successNode.classList.add("is-visible");
		successNode.innerHTML = message;

		signUpModule
			.querySelector(".js-homepage-email__input-wrapper")
			.classList.remove("is-visible");

		showInvalidBanner(signUpModule, false);
	};

	//Show or Hide invalid state. Hide Error state if showing.
	const showInvalidBanner = (signUpModule, shouldShow) => {
		if (shouldShow) {
			signUpModule.querySelector(".js-form__validation-error-address").classList.add("is-visible");
			showErrorBanner(signUpModule, false);
		} else {
			signUpModule.querySelector(".js-form__validation-error-address").classList.remove("is-visible");
		}
	};

	//Show or Hide error state. Hide Invalid state if showing.
	const showErrorBanner = (signUpModule, shouldShow, message) => {
		if (shouldShow) {
			const errorNode = signUpModule.querySelector(".js-form__validation__submit-error");
			errorNode.classList.add("is-visible");
			errorNode.innerHTML = message;
			showInvalidBanner(signUpModule, false);
		} else {
			signUpModule.querySelector(".js-form__validation__submit-error").classList.remove("is-visible");
		}
	};
};

const initializeEmailSignUp = () => {
	if (document.querySelector(".js-homepage-email__wrapper")) {
		emailSignUp();
	}
};

export default initializeEmailSignUp;