import marble from "@metmuseum/marble";

const visitComponent = () => {
	const visitComponent = document.querySelector(".js-visit-section");
	const visitSlides = visitComponent.querySelectorAll(".js-visit-slide");
	const initializeCarousel = () => {
		marble.carousel({
			cellAlign: "center",
			selectorString: ".js-visit-carousel",
			watchCSS: true,
			wrapAround: false,
			selectedAttraction: 0.1,
			friction: 0.8,
		});
		//Gotta work with Stephen to fine tune the accesibility updates we made
		visitComponent.querySelectorAll(".js-visit-section-link")
			.forEach(eL => {
				eL.setAttribute("tabindex", "0");
			});
	};

	const fetchLocationInformation = () => {
		//Fetch Museum Hours Information.
		fetch("/api/Ghidorah/LocationHours").then(response => response.json()
			//Handle the response and show the hours.
		).then(res => {
			showHours(res);
		}).catch(() => {
			visitSlides.forEach(visitSlide => {
				visitSlide.querySelector(".visit-section__hours-wrapper").classList.add("is-hidden");
			});
		});
	};

	const showHours = museumJSON => {
		//For each location returned find the corresponding tab and populate it's data.
		museumJSON.forEach(location => {
			const buildingName = location.BuildingName;
			const time = location.FormattedTime;
			visitSlides.forEach(visitSlide => {
				const museumLocation = visitSlide.getAttribute("data-location");
				if (buildingName.includes(museumLocation)) {
					visitSlide.querySelector(".js-visit-section__location-hours").innerHTML = time;
					location.Closed && visitSlide.querySelector(".visit-section__hours-wrapper").classList.add("is-closed");
				}
			});
		});
	};

	fetchLocationInformation();
	initializeCarousel();
};

const initializeVisitComponent = () => {
	if (document.querySelector(".js-visit-section")) {
		visitComponent();
	}
};

export default initializeVisitComponent;
