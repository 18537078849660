const exhibitionHero = document.querySelector(".js-edp-hero");
const exhibitionTabComponent = exhibitionHero?.querySelector(".js-edp-tabs");
const exhibitionTabs = exhibitionTabComponent ? Array.from(
	exhibitionTabComponent?.querySelectorAll(".js-edp-tabs__tab")
) : [];
const container = document.querySelector("#js-exhibition-detail-section-container");
let abortController = null;
let initialized = false;

const initializeExhibitionTabs = () => {
	if (exhibitionTabs.length < 1  || initialized === true) {
		return false;
	}

	exhibitionTabs.forEach(tab => {
		tab.addEventListener("click", e => {
			e.preventDefault();
			handleTabChange(tab);
		});
	});

	const initialActiveTab = findActiveTabByHref(window.location.pathname);
	setActiveTab(initialActiveTab);
	window.addEventListener("popstate", handlePopState);
	initalizeExhibtionLinks();
	initialized = true;
};

const initalizeExhibtionLinks = () => {
	const exhibitionLinks = document.querySelectorAll(".js-edp-link");

	exhibitionLinks.forEach(link => {
		link.addEventListener("click", e => {
			e.preventDefault();
			handleTriggeredTabChange(link);
		});
	});
};

const findActiveTabByHref = href => exhibitionTabs.find(
	tab => tab.getAttribute("href").match(new RegExp(`^${href}$`, "i"))
);

// forward and back buttons ;)
const handlePopState = () => {
	const activeTab = findActiveTabByHref(document.location.pathname);
	setActiveTab(activeTab);
	updateContainerWithNewContent(activeTab);
};

const handleTabChange = tab => {
	//Only attempt to load content if we aren't on the selected tab already.
	if (window.location.pathname !== tab.getAttribute("href")) {
		container.classList.add("is-loading");
		updateContainerWithNewContent(tab);
	}
};

const fetchContent = async (tab) => {
	const url = tab.getAttribute("href") + "?ajax=1";

	abortController && abortController.abort();
	abortController = new AbortController();

	const response = await fetch(url, {
		signal: abortController.signal,
		headers: { "X-Requested-With": "XMLHttpRequest" }
	});

	if (response.status === 200) {
		container.innerHTML = await response
			.text()
			.then(rawHTML => rawHTML)
			.catch(e => console.error(e));
		setActiveTab(tab);
		afterTabChangeCallbacks();
	} else {
		const error = "There was an error loading that page. Please try again later.";
		console.error(error);
		handleTabChangeError(error);
	}
};

const updateContainerWithNewContent = async tab => {
	try {
		await fetchContent(tab);
	} catch (e) {
		console.error(e);
	} finally {
		container.classList.remove("is-loading");
		abortController = null;
	}
}

const handleTabChangeError = (error) => {
	alert(error);
}

const afterTabChangeCallbacks = () => {
	pushVirtualPageviewDataLayerEvent();
	exhibitionTabComponent.dispatchEvent(new CustomEvent("newContentLoaded", { bubbles: true }));
	scrollTabsMenuIntoView();
	initalizeExhibtionLinks();
};

const pushVirtualPageviewDataLayerEvent = () => {
	if (!window.dataLayer) {
		console.error("No dataLayer was found, not doing anything");
	} else {
		window.dataLayer.push({
			event: "page_view",
			page: {
				path: window.location.pathname,
				title: document.title
			}
		});
	}
};

const handleTriggeredTabChange = link => {
	const linkHref = link.getAttribute("href");
	const tabToTrigger = exhibitionTabs.find(tab => tab?.getAttribute("href") === linkHref);
	tabToTrigger && handleTabChange(tabToTrigger);
};

const setActiveTab = activeTab => {
	try {
		exhibitionTabs.forEach(inactiveTab =>
			inactiveTab.classList.remove("is-active")
		);
		activeTab.classList.add("is-active");
		updateURL(activeTab);
	} catch (error) {
		console.error(error);
	}
};

const updateURL = tab => {
	const newURL = tab.getAttribute("href");
	!newURL.match(new RegExp(`^${document.location.pathname}$`, "i")) && window.history.pushState({}, null, newURL);
};

const scrollTabsMenuIntoView = () => {
	exhibitionTabComponent.scrollIntoView({
		behavior: "smooth",
		inline: "nearest",
	});
};

export default initializeExhibitionTabs;
